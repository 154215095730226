import React from 'react';
import { graphql, Link } from 'gatsby';
import { getKeyedResources } from '../../../utils/resource-helper';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Icon from '../../../components/Icon';
import Image from '../../../components/Image';
import Hero from '../../../components/Hero';
import SubfooterNav from '../../../components/SubfooterNav';
import Resource from '../../../components/Resource';
import bgImage from '../../../images/bg_ag_environmental_texture.jpg';
import '../../../styles/pages/_unit-sections.scss';
import Button from '../../../components/Button';
import Metadata from '../../../components/Metadata';

const AgEnvironmentalUnit5 = ({ data }) => {
  const heroBreadcrumbs = {
    unitLabel: 'UNIT 5',
    linkLabel: 'AG/ENVIRONMENTAL COURSE',
    to: '/classroom-instruction/ag-environmental/',
  };

  const {
    agSolutionSeekingMicrobesIntro,
    agSuperheroMicrobes,
    agYogurtFermentation,
    agMicrobesAndFood,
    agAntibioticsResistanceCombatingDisease,
    agBacterialDefense,
    agUsesOfCrisprAndBioethicalDecisionMaking,
    agDetectingWolbachia,
    agMicrobesAndBalanceInTheEnvironment,
    agDesignThinkingToIdentifyChallenges,
    agMicrobesToTheRescue,
  } = getKeyedResources(data);

  return (
    <Layout title="Ag-Environmental Unit 5">
      {/* -----------------UNIT BANNER----------------------- */}
      <Hero
        className={'hero--small course__hero'}
        modal
        imageSource="header-plant-unit5@2x.jpg"
        guidSrc="f83bf93b-5e5f-48d5-800d-a867b3133a1c"
        posterSrc={'unit-5-agplant-intro-video-still.gif'}
        breadcrumbs={heroBreadcrumbs}
        color={'green'}
        playtext={'Video Intro'}
      >
        <h1 className=" mb-0 pb-0">Solution Seeking Microbes</h1>
        <p className="pb-2">
          How can we harness the power of microbes to provide solutions to our
          local and global problems?
        </p>
      </Hero>
      {/* ----------------- AT A GLANCE RIBBON ----------------- */}
      <Section className="unit-sections__at-a-glance-section pt-0 pb-0">
        <Container
          fullWidth
          className="unit-sections__at-a-glance-container pt-2 pb-1"
        >
          <div className="course__unit-indicator text-bold">
            <div>AG/ENVIRONMENTAL | UNIT 5</div>
            <Link
              to="/classroom-instruction/ag-environmental/unit-6"
              className="course__unit-indicator-link"
            >
              UNIT 6 <Icon marginLeft name="arrowright" />
            </Link>
          </div>
          <h4 className="mt-0.5 text-bold">At a Glance</h4>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-storyline.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                STORYLINE
              </h3>
              <ul className="course__list course__list-style-none">
                <li className="mr-3">
                  Explore various properties of bacteria and the impact microbes
                  have on the environment, food production, and human health.
                </li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-pacing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                PACING
              </h3>
              <ul className="course__list">
                <li>9 week unit</li>
                <li>45-minute classes</li>
                <li>Built in flex days</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-framing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                FRAMING
              </h3>
              <ul className="course__list">
                <li>Standards Aligned</li>
                <li>Driving Question</li>
                <li>Engineering Design Process</li>
                <li>Project Based</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-lesson-plans-labs.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                LESSON PLANS AND LABS
              </h3>
              <ul className="course__list">
                <li>8 Lessons with Lab Elements</li>
                <li>2 Core Labs</li>
                <li>1 Final Project</li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* ----------------- COURSE CARDS INTRO ----------------- */}
      <Section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-0 pb-3"
      >
        <Container fullWidth className="pt-4 pb-1">
          <Row className="mb-2">
            <Column size={7}>
              <h3 className="text-bold">
                Lesson Plans, Labs and Student Capture Sheets
              </h3>
              <p>
                This unit includes lesson plans, labs, student capture sheets
                that empower educators to engage all students. These lesson
                bundles can be downloaded, for use in the classroom.
              </p>
            </Column>
            {/* Full unit zip file download */}
            <Column offset={1} size={4}>
              <div className="unit_download">
                <a href="/units/Gen-AG-U5.zip" className="button">
                  <Icon marginRight name="download" />
                  Complete Unit
                </a>
                <span className="unit_download">ZIP, 189.4 MB</span>
              </div>
            </Column>
          </Row>
        </Container>
        {/* ----------------- COURSE CARDS ----------------- */}
        <Container fullWidth>
          <Row>
            {/* INTRO */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agSolutionSeekingMicrobesIntro.tophat}
                tophatClass={`${agSolutionSeekingMicrobesIntro.page}__resource-tophat`}
                ribbon={agSolutionSeekingMicrobesIntro.ribbon}
                ribbonClass={`${agSolutionSeekingMicrobesIntro.page}__resource-ribbon`}
                img=""
                duration={agSolutionSeekingMicrobesIntro.duration}
                subject={agSolutionSeekingMicrobesIntro.subject}
                subjectClass={`${agSolutionSeekingMicrobesIntro.page}__resource-subject`}
                title={agSolutionSeekingMicrobesIntro.title}
                description={agSolutionSeekingMicrobesIntro.description}
                actions={agSolutionSeekingMicrobesIntro.actions}
              />
            </Column>
            {/* LESSON 1*/}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agSuperheroMicrobes.tophat}
                tophatClass={`${agSuperheroMicrobes.page}__resource-tophat`}
                ribbon={agSuperheroMicrobes.ribbon}
                ribbonClass={`${agSuperheroMicrobes.page}__resource-ribbon`}
                img=""
                duration={agSuperheroMicrobes.duration}
                subject={agSuperheroMicrobes.subject}
                subjectClass={`${agSuperheroMicrobes.page}__resource-subject`}
                title={agSuperheroMicrobes.title}
                description={agSuperheroMicrobes.description}
                actions={agSuperheroMicrobes.actions}
              />
            </Column>
            {/* LESSON 2 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agYogurtFermentation.tophat}
                tophatClass={`${agYogurtFermentation.page}__resource-tophat`}
                ribbon={agYogurtFermentation.ribbon}
                ribbonClass={`${agYogurtFermentation.page}__resource-ribbon`}
                img=""
                duration={agYogurtFermentation.duration}
                lab={agYogurtFermentation.lab}
                subject={agYogurtFermentation.subject}
                subjectClass={`${agYogurtFermentation.page}__resource-subject`}
                title={agYogurtFermentation.title}
                description={agYogurtFermentation.description}
                actions={agYogurtFermentation.actions}
              />
            </Column>
            {/* INTERACTIVE -- TBD */}
            {/* LESSON 3 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agMicrobesAndFood.tophat}
                tophatClass={`${agMicrobesAndFood.page}__resource-tophat`}
                ribbon={agMicrobesAndFood.ribbon}
                ribbonClass={`${agMicrobesAndFood.page}__resource-ribbon`}
                img=""
                duration={agMicrobesAndFood.duration}
                subject={agMicrobesAndFood.subject}
                subjectClass={`${agMicrobesAndFood.page}__resource-subject`}
                title={agMicrobesAndFood.title}
                description={agMicrobesAndFood.description}
                actions={agMicrobesAndFood.actions}
              />
            </Column>
            {/* LESSON 4 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agAntibioticsResistanceCombatingDisease.tophat}
                tophatClass={`${agAntibioticsResistanceCombatingDisease.page}__resource-tophat`}
                ribbon={agAntibioticsResistanceCombatingDisease.ribbon}
                ribbonClass={`${agAntibioticsResistanceCombatingDisease.page}__resource-ribbon`}
                img=""
                duration={agAntibioticsResistanceCombatingDisease.duration}
                subject={agAntibioticsResistanceCombatingDisease.subject}
                subjectClass={`${agAntibioticsResistanceCombatingDisease.page}__resource-subject`}
                title={agAntibioticsResistanceCombatingDisease.title}
                description={
                  agAntibioticsResistanceCombatingDisease.description
                }
                actions={agAntibioticsResistanceCombatingDisease.actions}
              />
            </Column>
            {/* LESSON 5 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agBacterialDefense.tophat}
                tophatClass={`${agBacterialDefense.page}__resource-tophat`}
                ribbon={agBacterialDefense.ribbon}
                ribbonClass={`${agBacterialDefense.page}__resource-ribbon`}
                img=""
                duration={agBacterialDefense.duration}
                subject={agBacterialDefense.subject}
                subjectClass={`${agBacterialDefense.page}__resource-subject`}
                title={agBacterialDefense.title}
                description={agBacterialDefense.description}
                actions={agBacterialDefense.actions}
              />
            </Column>
            {/* LESSON 6 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agUsesOfCrisprAndBioethicalDecisionMaking.tophat}
                tophatClass={`${agUsesOfCrisprAndBioethicalDecisionMaking.page}__resource-tophat`}
                ribbon={agUsesOfCrisprAndBioethicalDecisionMaking.ribbon}
                ribbonClass={`${agUsesOfCrisprAndBioethicalDecisionMaking.page}__resource-ribbon`}
                img=""
                duration={agUsesOfCrisprAndBioethicalDecisionMaking.duration}
                subject={agUsesOfCrisprAndBioethicalDecisionMaking.subject}
                subjectClass={`${agUsesOfCrisprAndBioethicalDecisionMaking.page}__resource-subject`}
                title={agUsesOfCrisprAndBioethicalDecisionMaking.title}
                description={
                  agUsesOfCrisprAndBioethicalDecisionMaking.description
                }
                actions={agUsesOfCrisprAndBioethicalDecisionMaking.actions}
              />
            </Column>
            {/* LESSON 7 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agDetectingWolbachia.tophat}
                tophatClass={`${agDetectingWolbachia.page}__resource-tophat`}
                ribbon={agDetectingWolbachia.ribbon}
                ribbonClass={`${agDetectingWolbachia.page}__resource-ribbon`}
                img=""
                duration={agDetectingWolbachia.duration}
                lab={agDetectingWolbachia.lab}
                subject={agDetectingWolbachia.subject}
                subjectClass={`${agDetectingWolbachia.page}__resource-subject`}
                title={agDetectingWolbachia.title}
                description={agDetectingWolbachia.description}
                actions={agDetectingWolbachia.actions}
              />
            </Column>
            {/* LESSON 8 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agMicrobesAndBalanceInTheEnvironment.tophat}
                tophatClass={`${agMicrobesAndBalanceInTheEnvironment.page}__resource-tophat`}
                ribbon={agMicrobesAndBalanceInTheEnvironment.ribbon}
                ribbonClass={`${agMicrobesAndBalanceInTheEnvironment.page}__resource-ribbon`}
                img=""
                duration={agMicrobesAndBalanceInTheEnvironment.duration}
                subject={agMicrobesAndBalanceInTheEnvironment.subject}
                subjectClass={`${agMicrobesAndBalanceInTheEnvironment.page}__resource-subject`}
                title={agMicrobesAndBalanceInTheEnvironment.title}
                description={agMicrobesAndBalanceInTheEnvironment.description}
                actions={agMicrobesAndBalanceInTheEnvironment.actions}
              />
            </Column>
            {/* LESSON 9 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agDesignThinkingToIdentifyChallenges.tophat}
                tophatClass={`${agDesignThinkingToIdentifyChallenges.page}__resource-tophat`}
                ribbon={agDesignThinkingToIdentifyChallenges.ribbon}
                ribbonClass={`${agDesignThinkingToIdentifyChallenges.page}__resource-ribbon`}
                img=""
                duration={agDesignThinkingToIdentifyChallenges.duration}
                subject={agDesignThinkingToIdentifyChallenges.subject}
                subjectClass={`${agDesignThinkingToIdentifyChallenges.page}__resource-subject`}
                title={agDesignThinkingToIdentifyChallenges.title}
                description={agDesignThinkingToIdentifyChallenges.description}
                actions={agDesignThinkingToIdentifyChallenges.actions}
              />
            </Column>
            {/* LESSON 10 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agMicrobesToTheRescue.tophat}
                tophatClass={`${agMicrobesToTheRescue.page}__resource-tophat`}
                ribbon={agMicrobesToTheRescue.ribbon}
                ribbonClass={`${agMicrobesToTheRescue.page}__resource-ribbon`}
                img=""
                duration={agMicrobesToTheRescue.duration}
                subject={agMicrobesToTheRescue.subject}
                subjectClass={`${agMicrobesToTheRescue.page}__resource-subject`}
                title={agMicrobesToTheRescue.title}
                description={agMicrobesToTheRescue.description}
                actions={agMicrobesToTheRescue.actions}
              />
            </Column>{' '}
          </Row>
        </Container>
      </Section>
      {/*---------------------BREADCRUMB NAV-----------------*/}
      <Section className="pb-0 pt-0">
        <SubfooterNav
          current={{
            name: 'Unit 5',
            link: '/classroom-instruction/ag-environmental/unit-5',
          }}
          next={{
            name: 'Unit 6',
            link: '/classroom-instruction/ag-environmental/unit-6',
          }}
          previous={{
            name: 'Overview',
            link: '/classroom-instruction/ag-environmental',
          }}
        />
      </Section>
    </Layout>
  );
};

export default AgEnvironmentalUnit5;

export const query = graphql`
  query AgenviroUnit5Resources {
    allResourcesJson(
      filter: { page: { eq: "ag-environmental" }, unit: { eq: 5 } }
    ) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
              guidSrc
              posterSrc
            }
          }
        }
      }
    }
  }
`;
